/**
 * MasterStartup.tsx
 *
 * this is a screen that shows upon the first time ever creating a table.
 * it's the first time the Initial Buy-in, Betting Structure (ante, min, max), and Chip Denominations settings are set.
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FreeTrialInterface, PrefInterface } from "poker-cows-common";
import { initializeTable } from "../../../logic/prompts/slice";
import {
  selectHostFreeTrial,
  selectNextPrefs,
} from "../../../logic/table/slice";
import { betSettingsInvariant } from "../../../utils/MoneyFormat";
import { InitialBuyInContent } from "../Settings/InitialSetup/InitialBuyInContent";
import { RulesRow } from "../Settings/RulesPages/RulesRow";
import { ChipDenomSetupContent } from "./MasterStartupComponents/ChipDenomSetupContent";
import { ChipDenomTitle } from "./MasterStartupComponents/ChipDenomTitle";
import { InitialBuyInTitle } from "./MasterStartupComponents/InitialBuyInTitle";
import NoLimitSetup from "./MasterStartupComponents/NoLimitSetup";
import { NoLimitSetupTitle } from "./MasterStartupComponents/NoLimitSetupTitle";
import SpreadLimitSetup from "./MasterStartupComponents/SpreadLimitSetup";
import { SpreadLimitSetupTitle } from "./MasterStartupComponents/SpreadLimitSetupTitle";

import Button from "../Button";
import { ModalName } from "../Modal/ModalConfig";
import { useModal } from "../Modal/hooks/useModal";
import { ModalProps } from "../Modal/types/modalTypes";

import { useHistory } from "react-router-dom";
import { MASTER_STARTUP_BUTTON } from "../../../test-identifiers";
import "../Settings/Settings.css";
import { Tooltip } from "../Tooltip/Tooltip";
import LowMethodSetup from "./MasterStartupComponents/LowMethodSetup";
import { LowMethodSetupTitle } from "./MasterStartupComponents/LowMethodSetupTitle";

const FreeTrialInfo = ({ freeTrial }: { freeTrial?: FreeTrialInterface }) => {
  if (!freeTrial || freeTrial.isPaying) {
    return null;
  }

  if (freeTrial.daysLeft > 0) {
    const daysText = freeTrial.daysLeft === 1 ? "day" : "days";
    return (
      <div className="freeTrialInfo">
        Your Premium trial expires in:{" "}
        <span className="whiteFont">
          {freeTrial.daysLeft} {daysText}
        </span>
      </div>
    );
  }

  if (freeTrial.handsLeft > 0) {
    const handsText = freeTrial.handsLeft === 1 ? "hand" : "hands";
    return (
      <div className="freeTrialInfo">
        Monthly Hand Limit Remaining:{" "}
        <span className="whiteFont">
          {freeTrial.handsLeft} {handsText}
        </span>
        <Tooltip
          title="Monthly Hand Limit"
          body="Free accounts have a 150 hand monthly limit that resets at the beginning of every month."
        />
      </div>
    );
  }

  if (freeTrial.expiredThisMonth) {
    return (
      <div className="freeTrialInfo alert">Your Premium trial has expired!</div>
    );
  }

  if (freeTrial.handsLeft <= 0) {
    return (
      <div className="freeTrialInfo alert">
        You have no free hands remaining this month!
      </div>
    );
  }

  return null;
};

export const MasterStartupContents = (props: ModalProps) => {
  const history = useHistory();
  const { closeAllModals } = useModal();
  const { nextPrefs } = useSelector(selectNextPrefs);
  const [newPrefs, setNewPrefs] = useState<Partial<PrefInterface>>(
    nextPrefs ?? {}
  );
  const dispatch = useDispatch();
  const { hostFreeTrial } = useSelector(selectHostFreeTrial);
  const canPlay =
    hostFreeTrial &&
    (hostFreeTrial.isPaying ||
      hostFreeTrial.daysLeft > 0 ||
      hostFreeTrial.handsLeft > 0);

  // set newPrefs, and tableInitialized to true so we know the masterStartup process has been triggered and dont show this screen again.
  useEffect(() => {
    setNewPrefs({
      ...newPrefs,
      tableInitialized: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(nextPrefs)]);

  // this runs when one of the dropdowns is changed and sets the new preferences
  const onUpdateInitialSettingsByPref = (
    prefsName: string,
    prefsValue: string
  ) => {
    betSettingsInvariant(
      prefsName,
      prefsValue,
      newPrefs,
      (updatedPrefs) => {
        setNewPrefs(updatedPrefs);
      },
      true /** Is Initialization */
    );
  };

  const onUpdatePrefs = (prefsName: string, prefsValue: string) => {
    setNewPrefs({
      ...newPrefs,
      [prefsName]: prefsValue,
    });
  };

  // this runs when the "start game" button is clicked, it's when the prefs are officially changed in state
  function onSavePrefsClick() {
    // TODO: Unsafe and could be replaced by something like https://github.com/davidmarkclements/fast-safe-stringify
    dispatch(initializeTable(JSON.stringify(newPrefs).replace(" ", "")));
  }

  return (
    <div className="masterContainer">
      <div className="masterStartupContainer">
        <div className="tableRulesWrapper">
          <RulesRow
            className="section"
            title={<InitialBuyInTitle />}
            content={
              <InitialBuyInContent
                onUpdateInitialSettingsByPref={onUpdateInitialSettingsByPref}
                startupPrefs={newPrefs}
              />
            }
            isTitleBold={true}
            isContentBigger={true}
          />
          <RulesRow
            className="section"
            title={<SpreadLimitSetupTitle />}
            content={
              <SpreadLimitSetup
                onUpdateInitialSettingsByPref={onUpdateInitialSettingsByPref}
                startupPrefs={newPrefs}
              />
            }
            isTitleBold={true}
            isContentBigger={true}
          />
          <RulesRow
            className="chipDenomination section"
            title={<ChipDenomTitle />}
            content={
              <ChipDenomSetupContent
                onUpdateInitialSettingsByPref={onUpdateInitialSettingsByPref}
                startupPrefs={newPrefs}
              />
            }
            isTitleBold={true}
            isContentBigger={true}
          />
          <RulesRow
            className="section"
            title={<LowMethodSetupTitle />}
            content={
              <LowMethodSetup
                startupPrefs={newPrefs}
                onUpdateInitialSettingsByPref={onUpdatePrefs}
              />
            }
            isTitleBold={true}
            isContentBigger={true}
          />
          <RulesRow
            className="section"
            title={<NoLimitSetupTitle />}
            content={
              <NoLimitSetup
                onUpdateInitialSettingsByPref={onUpdateInitialSettingsByPref}
                startupPrefs={newPrefs}
              />
            }
            isTitleBold={true}
            isContentBigger={true}
          />
        </div>
      </div>
      <footer className="modalFooter greenBackground">
        <div className="flexColumn">
          <FreeTrialInfo freeTrial={hostFreeTrial} />
          {canPlay && (
            <Button
              data-testid={MASTER_STARTUP_BUTTON}
              onClick={onSavePrefsClick}
              className="modalButtonPrimary normalFont"
            >
              CONFIRM AND CONTINUE
            </Button>
          )}
          {!canPlay && (
            <Button
              onClick={() => {
                closeAllModals();
                history.push("/payment-plan");
              }}
              className="modalButtonSecondary normalFont"
            >
              UPGRADE TO PREMIUM
            </Button>
          )}
        </div>
      </footer>
    </div>
  );
};

export const MasterStartup = () => {
  const { currentModalName, showModal } = useModal();

  useEffect(() => {
    if (!currentModalName) {
      showModal({
        name: ModalName.MasterStartupModal,
      });
    }
  }, [currentModalName, showModal]);

  return (
    <span className="actionAreaCallout">
      PLEASE SELECT THE INITIAL
      <br /> TABLE SETTINGS ABOVE
    </span>
  );
};
