import { FreeTrialInterface } from "poker-cows-common";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  selectHostFreeTrial,
  selectIsLocalPlayerHost,
} from "../../../../logic/table/slice";
import Button from "../../Button";
import { Tooltip } from "../../Tooltip/Tooltip";
import { useModal } from "../../Modal/hooks/useModal";
import "./HostFreeTrial.css";

const FreeTrialInfo = ({ freeTrial }: { freeTrial?: FreeTrialInterface }) => {
  if (!freeTrial || freeTrial.isPaying || freeTrial.daysLeft > 0) {
    return null;
  }

  return (
    <div className="freeTrial">
      Hands Remaining: <span className="whiteFont">{freeTrial.handsLeft}</span>
      <Tooltip
        title="Monthly Hand Limit"
        body="Free accounts have a 150 hand monthly limit that resets at the beginning of every month."
      />
    </div>
  );
};

export const HostFreeTrial = () => {
  const { hostFreeTrial } = useSelector(selectHostFreeTrial);
  const { isLocalPlayerHost } = useSelector(selectIsLocalPlayerHost);
  const { closeAllModals } = useModal();
  const history = useHistory();

  return (
    <div className="columnLayout">
      <FreeTrialInfo freeTrial={hostFreeTrial} />
      {!hostFreeTrial?.isPaying && isLocalPlayerHost && (
        <Button
          onClick={() => {
            closeAllModals();
            history.push("/payment-plan");
          }}
          className="modalButtonSecondary smallerButton"
        >
          UPGRADE TO PREMIUM
        </Button>
      )}
    </div>
  );
};
